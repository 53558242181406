import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { MenuIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/outline'

const navItems = [
  { name: "About", link: "#"},
  { name: "Blog", link: "#"},
  { name: "Projects", link: "#"},
  { name: "Resources", link: "#"}
]

export const NAVBAR_PADDING = "pt-16 sm:pt-20"

export default function Navbar() {
  const [open, setOpen] = useState(false);

  return (
    <nav className="bg-white border-b border-gray-100 w-full absolute z-10">
      <div className={`${open ? "border-b border-gray-100/50" : "border-none"} max-w-7xl mx-auto px-2 sm:px-6`}>
        <div className="relative flex items-center justify-between h-16 sm:h-20">
          <div className="flex-auto flex justify-start">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/">
                <StaticImage
                  className="block hover:opacity-80 transition"
                  layout="fixed"
                  formats={["auto", "webp", "avif"]}
                  src="../images/favicon.png"
                  width={40}
                  height={40}
                  quality={95}
                  alt="logo"
                />
                </Link>
            </div>
          </div>
          <div className="flex-auto flex justify-end">
            <div className="hidden sm:block">
              <div className="flex space-x-8">
                {navItems.map(item => (
                  <a href={item.link} className="text-slate-800 hover:text-primary-500 text-sm py-3">{ item.name }</a>
                ))}
                <a href="mailto:jerryliangglobal@gmail.com" class="bg-primary-500 hover:bg-primary-500/75 text-white text-sm px-5 py-3 rounded-sm">
                  Email me
                </a>
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center sm:hidden pr-2">
            <button type="button" onClick={() => setOpen(!open)} className="inline-flex items-center justify-center p-2 rounded-sm text-slate-800 bg-gray-100 focus:outline-none" aria-controls="mobile-menu">
              <span className="sr-only">Open main menu</span>
              <MenuIcon className={`${open ? "hidden" : "block"} h-6 w-6`} />
              <XIcon className={`${open ? "block" : "hidden"} h-6 w-6`} />
            </button>
          </div>
        </div>
      </div>
      <div className={open ? "block" : "hidden"} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1">
          {navItems.map(item => (
            <a href={item.link} className="text-slate-800 hover:text-primary-500 block px-3 py-2 text-base">{ item.name }</a>
          ))}
        </div>
      </div>
    </nav>
  )
}